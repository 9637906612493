
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import authService from "../../services/auth-service.js";

import Form from './form.js'
import { useEffect, useState, useContext } from "react";
import { AuthContext } from "context"; // Adjust the path as neede
import Loading from "layouts/loading/SimpleBackdrop"
import axios from "axios";
import MDButton from "components/MDButton/index.js";

function Tables() {
  const { isAuthenticated, userRole } = useContext(AuthContext);
  const { columns, rows,rUpdate } = authorsTableData();
  const { columns: pColumns, rows: pRows, pUpdate } = projectsTableData();
  const [view, setView] = useState('view')
  const [entityType, setEntityType] = useState('')
  const [selected, setSelected] = useState({})
  const researchCreateButtonClicked = async () =>{
  setView('create');
  setEntityType('research')
  }
  const projectCreateButtonClicked = async () =>{
  setView('create');
  setEntityType('project')
  }
  useEffect(() => {
    if (pUpdate.isCurrentStateUpdate || rUpdate.isCurrentStateUpdate) {
      setView('update');
      if (pUpdate.isCurrentStateUpdate) {
        setSelected(pUpdate.selectedProjectForUpdate);
        setEntityType('project');
      } else if (rUpdate.isCurrentStateUpdate) {
        setSelected(rUpdate.selectedResearchForUpdate);
        setEntityType('research');
      }
    }
  }, [pUpdate, rUpdate]);

  const closeUserProfile = () => {
    pUpdate.closeTheUpdateStateForm()
    rUpdate.closeTheUpdateStateForm()
    setView('view'); // Close the form
    pUpdate.pRefereshPage()
    rUpdate.rRefereshPage()
  };

  const projectExportData = async () => {
    try {
      const response = await axios.get('https://server.belestech.com/project/export', {
        responseType: 'blob', // Ensure binary data is handled properly
      });
  
      if (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement('a');
        a.href = url;
        a.download = 'project.xlsx'; // Set the desired filename
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url); // Clean up the object URL
      } else {
        console.error('Failed to export data.');
      }
    } catch (error) {
      console.error('Error exporting data:', error);
    }
  };
  const researchExportData = async () => {
    try {
      const response = await axios.get('https://server.belestech.com/research/export', {
        responseType: 'blob', // Ensure binary data is handled properly
      });
  
      if (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement('a');
        a.href = url;
        a.download = 'research.xlsx'; // Set the desired filename
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url); // Clean up the object URL
      } else {
        console.error('Failed to export data.');
      }
    } catch (error) {
      console.error('Error exporting data:', error);
    }
  };
  
  if (view === 'view'){
    if(pUpdate.pIsLoading || rUpdate.rIsLoading){
      return(<Loading />)
    }
    if(!pUpdate.pIsLoading && !rUpdate.rIsLoading){
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          {(userRole === 'admin' || userRole === "researchHead") && (
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white" display = 'flex' alignItems="bottom" gap="5px">
                    Researchs
                    <MDBox width="100%" style = {{display: "flex", justifyContent: "space-between"}}>
                      <MDBox onClick={researchCreateButtonClicked} style = {{cursor: "pointer"}}>
                        <img
                          src="/icons8-edit-100.png"
                          width={20}
                          alt="create"
                        />
                      </MDBox>
                      <MDBox onClick={researchExportData} style = {{cursor: "pointer"}}>
                        <img
                          src="/icons8-export-100.png"
                          width={30}
                          alt="export"
                        />
                      </MDBox>
                    </MDBox>
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MDBox>
              </Card>
            </Grid>)}
            {(userRole === 'admin' || userRole === "projectHead") && (
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white" display = 'flex' alignItems="bottom" gap="5px">
                    Projects
                    <MDBox width="100%" style = {{display: "flex", justifyContent: "space-between"}}>
                      <MDBox onClick={projectCreateButtonClicked} style = {{cursor: "pointer"}}>
                        <img
                          src="/icons8-edit-100.png"
                          width={20}
                          alt="create"
                        />
                      </MDBox>
                      <MDBox onClick={projectExportData} style = {{cursor: "pointer"}}>
                        <img
                          src="/icons8-export-100.png"
                          width={30}
                          alt="export"
                        />
                      </MDBox>
                    </MDBox>
                    
                    {/* <MDButton onClick={}>Export to Excel</MDButton> */}
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <DataTable
                    table={{ columns: pColumns, rows: pRows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MDBox>
              </Card>
            </Grid>
          )}
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    );}
  }
  if (view === 'create'){
    return (
      <Form 
        entity = {{}}
        isUpdateMode = {false}
        entityType = {entityType}
        onClose = {closeUserProfile}
      />
  )}
  if (view === 'update'){
    return (
      <Form
        entity = {selected}
        isUpdateMode = {true}
        entityType = {entityType}
        onClose = {closeUserProfile}
      />
  )}
  }

export default Tables;
