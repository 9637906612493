
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Events from "layouts/events";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import Files from 'layouts/files/index'
import TACO from 'layouts/testimonialsAndCareerOpportunites/index'

import UserProfile from "layouts/user-profile";
import UserManagement from "layouts/user-management";
import Publications from "layouts/publications"
import Login from "auth/login";
import Newsletters from "layouts/newsLetters";
import Messages from "layouts/messages"
import Register from "auth/register";
import ForgotPassword from "auth/forgot-password";
import ResetPassword from "auth/reset-password";

// @mui icons
import Icon from "@mui/material/Icon";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
    allowedRoles: ["admin", "researchHead", "projectHead"], 
  },
  {
    type: "collapse",
    name: "Research and Projects",
    key: "RAndP",
    icon: <Icon fontSize="small">biotech</Icon>,
    route: "/RAndP",
    component: <Tables />,
    allowedRoles: ["admin", "researchHead", "projectHead"], 
  },
  {
    type: "collapse",
    name: "Testimonials And Career Opportunites",
    key: "taco",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/taco",
    component: <TACO />,
    allowedRoles: ["admin"], 
  },
  
  // {
  //   type: "collapse",
  //   name: "RTL",
  //   key: "rtl",
  //   icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
  //   route: "/rtl",
  //   component: <RTL />,
  // },
  {
    type: "collapse",
    name: "Events",
    key: "events",
    icon: <Icon fontSize="small">event</Icon>,
    route: "/events",
    component: <Events />,
    allowedRoles: ["admin", "researchHead", "projectHead"], 
  },
  {
    type: "collapse",
    name: "Publications",
    key: "publications",
    icon: <Icon fontSize="small">public</Icon>,
    route: "/publications",
    component: <Publications />,
    allowedRoles: ["admin", "researchHead", "projectHead"], 
  },
  // {
  //   type: "collapse",
  //   name: "Billing",
  //   key: "billing",
  //   icon: <Icon fontSize="small">receipt_long</Icon>,
  //   route: "/billing",
  //   component: <Billing />,
  // },
  {
    type: "collapse",
    name: "Files",
    key: "files",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/files",
    component: <Files />,
    allowedRoles: ["admin"], 
  },
  {
    type: "collapse",
    name: "NewsLetters",
    key: "newsLetters",
    icon: <Icon fontSize="small">announcement</Icon>,
    route: "/newsLetters",
    component: <Newsletters />,
    allowedRoles: ["admin"], 
  },
  {
    type: "collapse",
    name: "Messages",
    key: "messages",
    icon: <Icon fontSize="small">email</Icon>,
    route: "/messages",
    component: <Messages />,
    allowedRoles: ["admin"], 
  },
  {
    type: "collapse",
    name: "Notifications",
    key: "notifications",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/notifications",
    component: <Notifications />,
    allowedRoles: ["admin", "researchHead", "projectHead"], 
  },
  // {
  //   type: "collapse",
  //   name: "Profile",
  //   key: "profile",
  //   icon: <Icon fontSize="small">person</Icon>,
  //   route: "/profile",
  //   component: <Profile />,
  // },
  // {
  //   type: "collapse",
  //   name: "Sign In",
  //   key: "sign-in",
  //   icon: <Icon fontSize="small">login</Icon>,
  //   route: "/authentication/sign-in",
  //   component: <SignIn />,
  // },
  {
    type: "examples",
    name: "User Profile",
    key: "user-profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/user-profile",
    component: <UserProfile />,
    allowedRoles: ["admin", "researchHead", "projectHead"], 
  },
  {
    type: "examples",
    name: "User Management",
    key: "user-management",
    icon: <Icon fontSize="small">list</Icon>,
    route: "/user-management",
    component: <UserManagement />,
    allowedRoles: ["admin"], 
  },
  // {
  //   type: "collapse",
  //   name: "Sign Up",
  //   key: "sign-up",
  //   icon: <Icon fontSize="small">assignment</Icon>,
  //   route: "/authentication/sign-up",
  //   component: <SignUp />,
  // },
  {
    type: "auth",
    name: "Login",
    key: "login",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/auth/login",
    component: <Login />,
    allowedRoles: ["admin", "researchHead", "projectHead"], 
  },
  // {
  //   type: "auth",
  //   name: "Register",
  //   key: "register",
  //   icon: <Icon fontSize="small">reigster</Icon>,
  //   route: "/auth/register",
  //   component: <Register />,
  //   allowedRoles: ["admin"], 
  // },
  {
    type: "auth",
    name: "Forgot Password",
    key: "forgot-password",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/auth/forgot-password",
    component: <ForgotPassword />,
    allowedRoles: ["admin", "researchHead", "projectHead"], 
  },
  {
    type: "auth",
    name: "Reset Password",
    key: "reset-password",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/auth/reset-password",
    component: <ResetPassword />,
    allowedRoles: ["admin"],
  },
];

export default routes;
