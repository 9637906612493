import React, { useState, useEffect } from "react";
import axios from "axios";
import Icon from "@mui/material/Icon";
import moment from "moment";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import AuthService from "../../../services/auth-service";
import team2 from "assets/images/team-2.jpg";

export default function CareerOpportunitiesTable() {
  const [isLoading, setIsLoading] = useState(false)
  const [opportunities, setOpportunities] = useState([]);
  const [selectedOpportunityForUpdate, setSelectedOpportunityForUpdate] = useState({});
  const [isCurrentStateUpdate, setIsCurrentStateUpdate] = useState(false);
  const [referesh, setReferesh] = useState(true)

  useEffect(() => {
    const fetchOpportunities = async () => {
      try {
        setIsLoading(true)
        const response = await AuthService.getOpportunities();
        if (response.error) {
          return console.error(response.error); // Log error if present
        }
        const allOpportunityData = response.data;

        // Fetch creator names for each opportunity
        const updatedData = await Promise.all(
          allOpportunityData.map(async (opportunity) => {
            const creator = await AuthService.getUser(opportunity.createdBy);
            return { ...opportunity, creatorName: creator.name };
          })
        );

        setOpportunities(updatedData);
        setIsLoading(false)
      } catch (error) {
        console.error("Error fetching career opportunity data:", error);
      }
    };

    fetchOpportunities();
  }, [referesh]);

  const oRefereshPage = () =>{
    setReferesh(referesh ? false : true)
  }

  const updateOpportunityClicked = (opportunity) => {
    setSelectedOpportunityForUpdate(opportunity);
    setIsCurrentStateUpdate(true);
  };

  const closeTheUpdateStateForm = () => {
    setIsCurrentStateUpdate(false);
    oRefereshPage()
  };

  const deleteOpportunityClicked = async (opportunityId) => {
    const isConfirmed = window.confirm(`Are you sure you want to delete this opportunity?`);
    if (!isConfirmed) return;
    setIsLoading(true)
    const response = await AuthService.deleteOpportunities(opportunityId);
    if (response.error) {
      return console.error(response.error);
    }

    // Remove deleted opportunity from state
    setOpportunities(opportunities.filter((opportunity) => opportunity.opportunityId !== opportunityId));
    setIsLoading(false)
  };

  const Opportunity = ({ name, description, requirements }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={team2} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDBox display="flex" flexDirection="column" gap="4px">
          <MDTypography variant="caption" fontWeight="medium" color="textSecondary">
            {description.length > 50 ? `${description.substring(0, 50)}...` : description}
          </MDTypography>
          <MDTypography variant="caption" color="textSecondary">
            {requirements.length > 50 ? `${requirements.substring(0, 50)}...` : requirements}
          </MDTypography>
        </MDBox>
      </MDBox>
    </MDBox>
  );

  // Generate rows based on opportunities data
  const rows = opportunities.map((opportunity) => ({
    opportunity: (
      <Opportunity
        name={opportunity.creatorName}
        description={opportunity.description}
        requirements={opportunity.requirements}
      />
    ),
    datePosted: (
      <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
        {moment(opportunity.datePosted).format("MMMM Do YYYY")}
        {/* {new Date(opportunity.datePosted).toLocaleDateString()} */}
      </MDTypography>
    ),
    action: (
      <MDBox display="flex" gap="8px">
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium" onClick={() => updateOpportunityClicked(opportunity)}>
          Edit
        </MDTypography>
        <MDTypography component="a" href="#" variant="caption" color="text" onClick={() => deleteOpportunityClicked(opportunity.opportunityId)} fontWeight="medium">
          Delete
        </MDTypography>
      </MDBox>
    ),
  }));

  return {
    columns: [
      { Header: "Opportunity", accessor: "opportunity", width: "40%", align: "left" },
      { Header: "Date Posted", accessor: "datePosted", align: "center" },
      { Header: "Action", accessor: "action", align: "center" },
    ],
    rows,
    oUpdate: {
      isCurrentStateUpdate,
      selectedOpportunityForUpdate,
      closeTheUpdateStateForm,
      oRefereshPage,
      oIsLoading: isLoading
    },
  };
}
