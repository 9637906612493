import { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";
import MenuItem from "@mui/material/MenuItem";
import { Select, InputLabel, FormControl } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import AuthService from "../../services/auth-service.js";
import ImagePreview from "examples/Forms/imagePreviews.js";
import Loading from "layouts/loading/SimpleBackdrop"
const EntityForm = ({ entity, isUpdateMode, entityType, onClose }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [notification, setNotification] = useState(false);
  const [entityData, setEntityData] = useState({
    title: "",
    description: "",
    date: "", // Only if entityType is 'research'
    startDate: "", // Only if entityType is 'project'
    endDate: "", // Only if entityType is 'project'
    status: "",
    researchId: "", // Only if entityType is 'project'
    leadResearch: "", // Only if entityType is 'research'
  });
  const [accounts, setAccounts] = useState([]);
  const [researchList, setResearchList] = useState([]);
  const [images, setImages] = useState([]); 
  const [notificationMessage, setNotificationMessage] = useState("");
  const [thumbnail, setThumbnail] = useState(null);
  const [initialImages, setInitialImages] = useState([]);
  const [existingImageIdsToDelete,setExistingImageIdsToDelete ] = useState([])

  const addNotification = async (msg) => {
    try {
      const response = await AuthService.createNotifications({data: {attributes: {msg}}})
      console.log(response)
      // setNotifications((prev) => [...prev, response]);
    } catch (error) {
      console.error("Error adding notification:", error);
    }
  };

  // Fetch accounts for the leadResearch select dropdown
  useEffect(() => {
    const fetchAccounts = async () => {
      setIsLoading(true)
      const response = await AuthService.getUsers(); // Adjust this service call
      setAccounts(response)
      setIsLoading(false)
    };
    fetchAccounts();
  }, []);

  // Fetch research IDs for the researchId select dropdown
  useEffect(() => {
    const fetchResearchList = async () => {
      setIsLoading(true)
      const response = await AuthService.getResearches(); // Adjust this service call
      setResearchList(response.data);
      setIsLoading(true)
    };
    fetchResearchList();
  }, []);

  useEffect(() => {
    if (notification) {
      const timer = setTimeout(() => setNotification(false), 5000);
      return () => clearTimeout(timer);
    }
  }, [notification]);

  useEffect(() => {
    if (entity) {
      setEntityData({ ...entity });
    }
  }, [entity]);

  const handleChange = (e) => {
    setEntityData({ ...entityData, [e.target.name]: e.target.value });
  };
  
  useEffect(() => {
    try {
      if (isUpdateMode && entity) {
        const getImgsForTheEntity = async () => {
          const response =
            entityType === "research"
              ? await AuthService.getResearchImage(entity.researchId)
              : await AuthService.getProjectImage(entity.projectId);
          return response.data; // Ensure response.data is returned
        };
    
        // Call the async function and wait for its result
        const fetchImages = async () => {
          setIsLoading(true)
          const imgs = await getImgsForTheEntity(); // Await the result
          setInitialImages(imgs); // Set the images in state
          const index = imgs.findIndex(img => img.isThumbnail);
          setThumbnail(index !== -1 ? index : null);
          setIsLoading(false)
          // setThumbnail(imgs.find(img => img.isThumbnail)?.id || null); // Set the thumbnail
        };
        
        fetchImages(); // Invoke the async function
      }
    } catch (error) {
      console.error(error)
    }
    
  }, [entity, isUpdateMode, entityType]); // Added entityType to dependencies if needed
  
  const submitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    // Validate form data
    if (!entityData.title || !entityData.description) {
      setNotificationMessage("Please fill in all required fields.");
      setNotification(true);
      return;
    }
  
    // Convert dates to the required format
    if (entityType === "project") {
      entityData.startDate = dateChanger(entityData.startDate);
      entityData.endDate = dateChanger(entityData.endDate);
    } else if (entityType === "research") {
      entityData.date = dateChanger(entityData.date);
    }
  
    try {
      const fullData = { data: { attributes: entityData } };
      let response;
  
      // Step 1: Create or update the main entity
      if (isUpdateMode) {
        response = entityType === "research"
          ? await AuthService.updateResearches({ fullResearchData: fullData, researchId: entityData.researchId })
          : await AuthService.updateProjects({ fullProjectData: fullData, projectId: entityData.projectId });
      } else {
        response = entityType === "research"
          ? await AuthService.createResearches(fullData)
          : await AuthService.createProjects(fullData);
      }
  
      if (response && response.id) {
        const createdId = response.id;
  
        // Step 2: Handle image uploads, if there are images to upload
        if (images.length > 0 || existingImageIdsToDelete.length > 0 || thumbnail != null) {
          const fullUserData = {
            existingImageIdsToDelete,  // only include for updates
            data: {
              attributes: {
                [entityType === "research" ? "researchId" : "projectId"]: createdId,
                thumbnailIndex: thumbnail
              }
            }
          };
  
          const formData = new FormData();
          images.length > 0  && images.forEach((file) => formData.append('images', file));
          formData.append("data", JSON.stringify(fullUserData));
          // Call appropriate service for image uploads
          const uploadResponse = isUpdateMode
            ? (entityType === "research"
                ? await AuthService.updateResearchImages({ fullResearchImagesData: formData, researchImagesId: entityData.researchId })
                : await AuthService.updateProjectImages({ fullProjectImagesData: formData, projectImagesId: entityData.projectId })
              )
            : (entityType === "research"
                ? await AuthService.createResearchImages(formData)
                : await AuthService.createProjectImages(formData)
              );
  
          if (!uploadResponse) {
            console.error("Image upload failed");
          }
        }
        // await addNotification(`${entityType} ${entity.title} ${isUpdateMode ? "updated" : "created"}.`)
        // Show success message and close the form
        if(isUpdateMode){
          await addNotification(`${entityType} ${entity.title} updated.`)
        }else {
          await addNotification(`new ${entityType} created.`)
        }
        setIsLoading(false)
        setNotificationMessage(`${entityType} ${isUpdateMode ? "updated" : "created"} successfully!`);
        setNotification(true);
        if (onClose) onClose();
      }
    } catch (error) {
      console.error("Error creating or updating entity:", error);
      setNotificationMessage(`Error: ${error.message || "An error occurred"}`);
      setNotification(true);
    }
  };
  
  
  const dateChanger = (dated) =>{
    const date = new Date(dated);
    const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
    return formattedDate
  }
  if(entityType === 'project'){
    entityData.startDate = dateChanger(entityData.startDate)
    entityData.endDate = dateChanger(entityData.endDate)
  }
  if(entityType === 'research'){
    entityData.date = dateChanger(entityData.date)
  }
  // Handle file selection and validations
  const handleImageChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const totalSize = selectedFiles.reduce((acc, file) => acc + file.size, 0) + 
                      images.reduce((acc, file) => acc + file.size, 0);

    if (selectedFiles.length + images.length > 6) {
      setNotificationMessage("Maximum of 6 images allowed.");
      setNotification(true);
      return;
    }
    
    if (totalSize > 30 * 1024 * 1024) {
      setNotificationMessage("Total file size should not exceed 30 MB.");
      setNotification(true);
      return;
    }

    setImages([...images, ...selectedFiles]);
  };

  const handleRemoveImage = (img, index, isInitial = false) => {
    if (isInitial) {
        // Remove from initialImages
        setInitialImages((prev) => prev.filter((_, i) => i !== index));
        setExistingImageIdsToDelete((prev) => [...prev, img.imgId]);
    } else {
        // Adjust index for images based on offset
        const adjustedIndex = index - initialImages.length;
        setImages((prev) => prev.filter((_, i) => i !== adjustedIndex));
    }

    // Uncomment if you want to handle URL revocation
    // if (img instanceof File) {
    //     URL.revokeObjectURL(img);
    // }
};

 
  const handleThumbnailSelection = (imageId) => setThumbnail(imageId);

  const shouldShowImageUpload =
  (entityType === "research" || entityType === "project") && images.length < 6;
  if(isLoading){
    return(<Loading />)
  }
  if(!isLoading){
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <MDBox component="form" role="form" onSubmit={submitHandler} display="flex" flexDirection="column">
        <MDTypography variant="h5">
          {isUpdateMode ? `Update ${entityType}` : `Create ${entityType}`}
        </MDTypography>

        {/* Title Input */}
        <MDBox mb={2}>
          <MDTypography variant="body2">Title</MDTypography>
          <MDInput type="text" name="title" value={entityData.title} onChange={handleChange} fullWidth />
        </MDBox>

        {/* Description Text Area */}
        <MDBox mb={2}>
          <MDTypography variant="body2">Description</MDTypography>
          <MDInput type="textarea" multiline rows ={2} name="description" value={entityData.description} onChange={handleChange} fullWidth />
        </MDBox>

        {/* Date Input for Research (if entityType is 'research') */}
        {entityType === "research" && (
          <MDBox mb={2}>
            <MDTypography variant="body2">Date</MDTypography>
            <MDInput type="date" name="date" value={entityData.date} onChange={handleChange} fullWidth />
          </MDBox>
        )}

        {/* Start Date and End Date Inputs for Projects (if entityType is 'project') */}
        {entityType === "project" && (
          <MDBox  >
            <MDBox mb={2} >
              <MDTypography variant="body2">Start Date</MDTypography>
              <MDInput fullWidth type="date" name="startDate" value={entityData.startDate} onChange={handleChange}  />
            </MDBox>
            <MDBox mb={2} >
              <MDTypography variant="body2">End Date</MDTypography>
              <MDInput fullWidth type="date" name="endDate" value={entityData.endDate} onChange={handleChange} />
            </MDBox>
          </MDBox>
        )}
       
        {/* Lead Research Select for Research */}
        {entityType === "research" && (
          <MDBox mb={2}>
            <MDTypography variant="body2">Lead Research</MDTypography>
            <FormControl fullWidth>
              <InputLabel>Lead Research</InputLabel>
              <Select name="leadResearch" value={entityData.leadResearch} onChange={handleChange}>
              <MenuItem key='first' value=''>
                      select one
                    </MenuItem>
                {accounts &&
                  accounts.map((account) => (
                    <MenuItem key={account.userName} value={account.userName}>
                      {account.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </MDBox>
        )}

        {/* Status Select */}
        <MDBox mb={2}>
          <MDTypography variant="body2">Status</MDTypography>
          <FormControl fullWidth>
            <InputLabel>Status</InputLabel>
            <Select name="status" value={entityData.status} onChange={handleChange}>
              <MenuItem value="ongoing">Ongoing</MenuItem>
              <MenuItem value="completed">Completed</MenuItem>
              <MenuItem value="starting">Pending</MenuItem>
            </Select>
          </FormControl>
        </MDBox>

        {/* Research ID Select (for projects only) */}
        {entityType === "project" && (
          <MDBox mb={2}>
            <MDTypography variant="body2">Research ID</MDTypography>
            <FormControl fullWidth>
              <InputLabel>Research ID</InputLabel>
              <Select name="researchId" value={entityData.researchId} onChange={handleChange}>
                {researchList &&
                  researchList.map((research) => (
                    <MenuItem key={research.researchId} value={research.researchId}>
                      {research.title}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </MDBox>
        )}
        {/* Conditional Image Upload Input
        {shouldShowImageUpload && (
        <MDBox mb={2}>
          <MDTypography variant="body2">
            {isUpdateMode ? Update ${entityType} Images : Add ${entityType} Images}
          </MDTypography>
          <MDInput
            name = 'images'
            type="file"
            inputProps={{ multiple: true }}
            onChange={handleImageChange}
            fullWidth
          />
        </MDBox>
      )} */}
       {/* Image Upload and Preview Section */}
       <MDBox mb={2}>
          <MDInput type="file" inputProps={{ multiple: true }} onChange={handleImageChange} fullWidth />
          
      <MDBox display="flex" flexDirection="row" flexWrap="wrap">
        {/* Display initial images */}
        {initialImages && (initialImages.map((img, index) => (
          <ImagePreview
            key={img.id}
            image={img}
            isThumbnail={index === thumbnail}
            onRemove={() => handleRemoveImage(img, index, true)}
            onSelectThumbnail={() => handleThumbnailSelection(index)}
            noThumbnail = {false}
          />
        )))}
        {/* Display new images */}
        {images.map((img, index) => (
          <ImagePreview
            key={index}
            image={img}
            isThumbnail={index === thumbnail}
            onRemove={() => handleRemoveImage(index)}
            onSelectThumbnail={() => handleThumbnailSelection(index)}
            noThumbnail = {false}
          />
        ))} 
      </MDBox>
        </MDBox>

        {/* Notification */}
        {notification && (
          <MDAlert color="info" mt="20px">
            <MDTypography variant="body2" color="green">
            {notification && <MDAlert color="info">{notificationMessage}</MDAlert>}
              
            </MDTypography>
          </MDAlert>
        )}

        {/* Submit Button */}
        <MDBox display="flex" justifyContent="space-between">
          <MDButton variant="outlined" color="secondary" onClick={onClose}>
            Close
          </MDButton>
          <MDButton variant="gradient" color="info" type="submit">
            {isUpdateMode ? "Update" : "Create"}
          </MDButton>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};}

export default EntityForm;
