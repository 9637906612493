import { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";
import MenuItem from "@mui/material/MenuItem";
import { Select, InputLabel, FormControl } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import AuthService from "../../services/auth-service.js";
import Loading from "layouts/loading/SimpleBackdrop"

const EntityForm = ({ entity, isUpdateMode, entityType, onClose }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [notification, setNotification] = useState(false);
  const [entityData, setEntityData] = useState({
    quote: "",
    description: "",
    date: "",
    startDate: "", 
    endDate: "", 
    requirements: "",
    opportunityId: "",
    createdBy: "",
    testimonialsId: "",
    userId: "",
    status:"",
    link:""
  });
  const [accounts, setAccounts] = useState([]);
  // const [testimonialList, setTestimonialList] = useState([]);
  const [notificationMessage, setNotificationMessage] = useState("");
   
  useEffect(() => {
    const fetchAccounts = async () => {
      setIsLoading(true)
      const response = await AuthService.getUsers(); // Adjust this service call
      setAccounts(response)
      setIsLoading(false)
    };
    fetchAccounts();
  }, []);
  useEffect(() => {
    if (notificationMessage) {
      const timer = setTimeout(() => setNotificationMessage(false), 5000);
      return () => clearTimeout(timer);
    }
  }, [notificationMessage]);

  useEffect(() => {
    if (entity) {
      setEntityData({ ...entity });
    }
  }, [entity]);

  const handleChange = (e) => {
    setEntityData({ ...entityData, [e.target.name]: e.target.value });
  };
  const calculateStatus = () => {
    const currentDate = new Date();
    const endDate = new Date(entityData.endDate);
    entityData.status = currentDate > endDate ? "Closed" : "Open";
  };
  const calculateStatusForPreview = () => {
    const currentDate = new Date();
    const endDate = new Date(entityData.endDate);
    return currentDate > endDate ? "Closed" : "Open";
  };
  
  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true)
      calculateStatus()
      const fullData = { data: { attributes: entityData } };
      let response;
      // Step 1: Create or update the main entity
      if (isUpdateMode) {
        response = entityType === "testimonial"
          ? await AuthService.updateTestimonials({ fullTestimonialData: fullData, testimonialId: entityData.testimonialsId })
          : await AuthService.updateOpportunities({ fullOpportunitieData: fullData, opportunitieId: entityData.opportunityId });
      } else {
        response = entityType === "testimonial"
          ? await AuthService.createTestimonials(fullData)
          : await AuthService.createOpportunities(fullData);
      }
      setIsLoading(false)
        setNotificationMessage(`${entityType} ${isUpdateMode ? "updated" : "created"} successfully!`);
        setNotification(true);
        if (onClose) onClose();
      // }
    } catch (error) {
      console.error("Error creating or updating entity:", error);
      setNotificationMessage(`Error: ${error.message || "An error occurred"}`);
      setNotification(true);
    }
  };
  const dateChanger = (dated) =>{
    const date = new Date(dated);
    const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
    return formattedDate
  } 
  if(entityType === 'opportunity'){
    entityData.startDate = dateChanger(entityData.startDate)
    entityData.endDate = dateChanger(entityData.endDate)
  }
  if(entityType === 'testimonial'){
    entityData.date = dateChanger(entityData.date)
  }
  if(isLoading){
    return(<Loading />)
  }
  if(!isLoading){
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <MDBox component="form" role="form" onSubmit={submitHandler} display="flex" flexDirection="column">
        <MDTypography variant="h5">
          {isUpdateMode ? `Update ${entityType}` : `Create ${entityType}`}
        </MDTypography>

        {/* Description Text Area */}
        {entityType === "opportunity" && (<MDBox mb={2}>
          <MDTypography variant="body2">Description</MDTypography>
          <MDInput type="textarea" name="description" value={entityData.description} onChange={handleChange} fullWidth />
        </MDBox>)}
        {/* requirements Text Area */} 
        {entityType === "opportunity" && (<MDBox mb={2}>
          <MDTypography variant="body2">requirements</MDTypography>
          <MDInput type="textarea" name="requirements" value={entityData.requirements} onChange={handleChange} fullWidth />
        </MDBox>)}
        {entityType === "testimonial" && (<MDBox mb={2}>
          <MDTypography variant="body2">quote</MDTypography>
          <MDInput type="textarea" name="quote" value={entityData.quote} onChange={handleChange} fullWidth />
        </MDBox>)}

        {/* Date Input for Research (if entityType is 'research') */}
        {entityType === "testimonial" && (
          <MDBox mb={2}>
            <MDTypography variant="body2">Date</MDTypography>
            <MDInput type="date" name="date" value={entityData.date} onChange={handleChange} fullWidth />
          </MDBox>
        )}

        {/* Start Date and End Date Inputs for Projects (if entityType is 'project') */}
        {entityType === "opportunity" && (
          <MDBox  >
            <MDBox mb={2} >
              <MDTypography variant="body2">Start Date</MDTypography>
              <MDInput fullWidth type="date" name="startDate" value={entityData.startDate} onChange={handleChange}  />
            </MDBox>
            <MDBox mb={2} >
              <MDTypography variant="body2">End Date</MDTypography>
              <MDInput fullWidth type="date" name="endDate" value={entityData.endDate} onChange={handleChange} />
            </MDBox>
          </MDBox>
        )}
       
        {/* Lead Research Select for Research */}
        {entityType === "testimonial" && (
          <MDBox mb={2}>
            <MDTypography variant="body2">userId</MDTypography>
            <FormControl fullWidth>
              <InputLabel>userId</InputLabel>
              <Select name="userId" value={entityData.userId} onChange={handleChange}>
              <MenuItem key='first' value=''>
                      select one
                    </MenuItem>
                {accounts &&
                  accounts.map((account) => (
                    <MenuItem key={account.userName} value={account.userName}>
                      {account.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </MDBox>
        )}
        {/* Status Select */}
        {entityType === "opportunity" && (
        <MDBox mb={2}>
          <MDTypography variant="body2">Status</MDTypography>
          <MDInput 
            name="status"
            value={calculateStatusForPreview()}
            onChange={handleChange}
            fullWidth 
            disabled 
          />
        </MDBox>)}
        {/* Lead Research Select for Research */}
        {entityType === "opportunity" && (
          <MDBox mb={2} >
            <MDTypography variant="body2">created By</MDTypography>
            <FormControl p={3}  fullWidth>
              <InputLabel>created By</InputLabel>
              <Select name="createdBy" className="p-2" value={entityData.createdBy} onChange={handleChange}>
              <MenuItem key='first' value=''>
                      select one
                    </MenuItem>
                {accounts &&
                  accounts.map((account) => (
                    <MenuItem key={account.userName} value={account.userName}>
                      {account.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </MDBox>
        )}
        {entityType === "opportunity" && (<MDBox mb={2}>
          <MDTypography variant="body2">Link</MDTypography>
          <MDInput type="textarea" name="link" value={entityData.link} onChange={handleChange} fullWidth />
        </MDBox>)}

        {/* Notification */}
        {notification && (
          <MDAlert color="info" mt="20px">
            <MDTypography variant="body2" color="green">
              {isUpdateMode ? `${entityType} has been updated` : `${entityType} created successfully`}
            </MDTypography>
          </MDAlert>
        )}

        {/* Submit Button */}
        <MDBox display="flex" justifyContent="space-between">
          <MDButton variant="outlined" color="secondary" onClick={onClose}>
            Close
          </MDButton>
          <MDButton variant="gradient" color="info" type="submit">
            {isUpdateMode ? "Update" : "Create"}
          </MDButton>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};}

export default EntityForm;
