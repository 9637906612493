// Imports for React hooks
import { useEffect, useState } from "react";

// Other necessary imports
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import AuthService from "../../../services/auth-service";
import team2 from "assets/images/team-2.jpg";

export default function Data() {
  const [isLoading, setIsLoading] = useState(false)
  const [researchData, setResearchData] = useState([]);
  const [selectedResearchForUpdate, setSelectedResearchForUpdate] = useState({})
  const [isCurrentStateUpdate, setIsCurrentStateUpdate] = useState(false)
  const [referesh, setReferesh] = useState(true)
 
  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true)
        const data = await AuthService.getResearches();
        if (data.error) {
          return console.log(data.error); // Log the error if the response contains an error
        }
  
        const allResearchData = data.data;
  
        // Fetch lead names for each research in parallel using Promise.all
        const updatedData = await Promise.all(
          allResearchData.map(async (research) => {
            // Fetch lead researcher's data based on research.leadResearch
            const researchLead = await AuthService.getUser(research.leadResearch);
            return { ...research, leadName: researchLead.name };
          })
        );
        setResearchData(updatedData);
        setIsLoading(false)
      } catch (error) {
        console.error("Error fetching research data:", error);
      }
    }
  
    fetchData();
  }, [referesh]);
  const updateResearchClicked = (research) => {
    setSelectedResearchForUpdate(research)
    setIsCurrentStateUpdate(true)
  }
  const rRefereshPage = () =>{
    setReferesh(referesh ? false : true)
  }
  const closeTheUpdateStateForm = ()=>{
    setIsCurrentStateUpdate(false)
    rRefereshPage()
  }
  
  const deleteResearchClicked = async (reserchId) => {
    const isConfirmed = window.confirm(`Are you sure you want to delete user ${reserchId}?`);
    
    // If user cancels, do nothing
    if (!isConfirmed) return;
    setIsLoading(true)
    const response = await AuthService.deleteResearches(reserchId);
    if(response.error){
      return console.error(response.error)
    }
    setIsLoading(false)
    rRefereshPage()
  }


  // Define Author and Job components
  const Author = ({ image, name, title, description }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      {/* <MDAvatar src={image || team2} name={name} size="sm" /> */}
      <MDBox ml={2} lineHeight={1}  display="flex" flexDirection="column" gap="2px">
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        {/* Display the title and part of the description */}
        {/* <MDBox display= 'flex' flex-direction = 'column' gap = '4px'> */}
          <MDTypography variant="caption" fontWeight="medium" color="textSecondary">
            {title}
          </MDTypography>
          <MDTypography variant="caption" color="textSecondary">
            {description.length > 30 ? `${description.substring(0, 30)}...` : description}
          </MDTypography>
        {/* </MDBox> */}
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  // Map fetched data to rows
  const rows = researchData.map((research) => ({
    // Author now includes title and description in the research
    research: (
      <Author
        image={research.image}
        title={research.title}
        description={research.description}
      />
    ),
    lead: (
      <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
        {research.leadName}
      </MDTypography>
    ),
    status: (
      <MDBox ml={-1}>
        <MDBadge
          badgeContent={research.status}
          color={research.status === "completed" ? "success" : "dark"}
          variant="gradient"
          size="sm"
        />
      </MDBox>
    ),
    date: (
      <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
        {new Date(research.date).toLocaleDateString()}
      </MDTypography>
    ),
    action: (
      <MDBox display = 'flex' gap = '8px'>
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium" onClick = {() => updateResearchClicked(research)}>
        Edit
      </MDTypography>
      <MDTypography component="a" href="#" variant="caption" color="text" onClick = {() => deleteResearchClicked(research.researchId)} fontWeight="medium">
      Delete
    </MDTypography>
      </MDBox>
    ),
  }));

  // Return columns and rows
  return {
    columns: [
      { Header: "Research", accessor: "research", width: "45%", align: "left" },
      { Header: "Lead", accessor: "lead", align: "left" },
      { Header: "Status", accessor: "status", align: "center" },
      { Header: "Date", accessor: "date", align: "center" },
      { Header: "Action", accessor: "action", align: "center" },
    ],
    rows,
    rUpdate: {
      isCurrentStateUpdate,
      selectedResearchForUpdate,
      closeTheUpdateStateForm,
      rRefereshPage,
      rIsLoading: isLoading
    }
  };
}


