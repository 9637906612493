
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

import React, { useEffect, useState } from "react";
import AuthService from "../../services/auth-service";


export default function Data() {
  const [isLoading, setIsLoading] = useState(false)
  const [userData, setUserData] = useState([]);
  const [selectedUserForUpdate, setSelectedUserForUpdate] = useState({})
  const [isCurrentStateUpdate, setIsCurrentStateUpdate] = useState(false)
  const [referesh, setReferesh] = useState(true)

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true)
        const response = await AuthService.getUsers();
     
        const data = await response;

        if(data.error){
          return console.error(error)
        }
        setUserData(data); 
        setIsLoading(false)
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    }
    fetchData();
  }, [referesh]);
  const rRefereshPage = () =>{
    setReferesh(referesh ? false : true)
  }
  // Function to render the user profile
  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  // Function to render the user role
  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );
  const deleteUserClicked = async (userName) => {
    setIsLoading(true)
    const isConfirmed = window.confirm(`Are you sure you want to delete user ${userName}?`);
    // If user cancels, do nothing
    if (!isConfirmed) return;

    const response = await AuthService.deleteUsers(userName);
    if(response.error){
      return console.error(response.error)
    }
    rRefereshPage()
    setIsLoading(false)
  }
  const updateUserClicked = async (user) => {
    setIsCurrentStateUpdate(true)
    setSelectedUserForUpdate(user)
  }
  const closeTheUpdateStateForm = ()=>{
    setIsCurrentStateUpdate(false)
    rRefereshPage()
  }
  const imgLinkReplacer = (link) => {
    try {
      const updatedLoc = link.replaceAll("\\", "/").split("/").pop();
      return `https://server.belestech.com/uploads/${updatedLoc}`;
    } catch (error) {
      console.error(error);
      return "";
    }
  };
  return {
    columns: [
      { Header: "user", accessor: "user", width: "45%", align: "left" },
      { Header: "email", accessor: "email", align: "left" },
      { Header: "role", accessor: "role", align: "left" },
      { Header: "contact info.", accessor: "contactInfo", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],
    
    // Dynamically populate rows based on API response
    rows: userData.map((user) => ({
      user: <Author image={imgLinkReplacer(user.img) || team2} name={user.name} email={user.email} />,
      role: <Job title={user.role} description="" />,
      email: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {user.email}
        </MDTypography>
      ),
      contactInfo: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {user.contactInfo}
        </MDTypography>
      ),
      action: (
        <MDBox>
          <MDTypography component="a" href="#" variant="caption" color="text" onClick = {() => updateUserClicked(user)} fontWeight="medium" mr={2}>
            Edit
          </MDTypography>
          <MDTypography component="a" href="#" variant="caption" color="text" onClick = {() => deleteUserClicked(user.userName)} fontWeight="medium">
            Delete
          </MDTypography>
        </MDBox>
      ),
    })),
    update: {
      isCurrentStateUpdate,
      selectedUserForUpdate,
      closeTheUpdateStateForm,
      isLoading,
      rRefereshPage,
    }
  };
}


