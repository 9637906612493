import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import AuthService from "../../../services/auth-service";  // Assuming you're using the same service

export default function MessagesData() {
  const [isLoading, setIsLoading] = useState(false)
  const [messages, setMessages] = useState([]);
  const [referesh, setReferesh] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true)
        const data = await AuthService.getMessages(); // Update API call to get message data
        if (data.error) {
          return console.log(data.error); // Log the error if the response contains an error
        }

        const allMessages = data.data;
        setMessages(allMessages);
        setIsLoading(false)
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    }

    fetchData();
  }, [referesh]);

  const rRefereshPage = () => {
    setReferesh(referesh ? false : true)
  };

  // Define Message sender component
  const Sender = ({ name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox ml={2} lineHeight={1} display="flex" flexDirection="column" gap="2px">
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
        {email}
      </MDTypography>
      </MDBox>
    </MDBox>
  );

  // Map fetched data to rows
  const rows = messages.map((message) => ({
    sender: (
      <Sender
        name={message.fullname} // Assuming sender is a string with the sender's name
        email={message.email} 
      />
    ),
    message: (
      <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
        {message.message} {/* Assuming message has a text content */}
      </MDTypography>
    ),
    date: (
      <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
        {new Date(message.date).toLocaleDateString()} {/* Assuming dateSent is a valid date string */}
      </MDTypography>
    ),
  }));

  return {
    columns: [
      { Header: "Sender", accessor: "sender", width: "35%", align: "left" },
      { Header: "Message", accessor: "message", align: "left" },
      { Header: "Date", accessor: "date", align: "center" },
    ],
    rows,
    update: {
      rRefereshPage,
      isLoading
    }
  };
}
