import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import messagesData from "layouts/messages/data/messagesData.js"; // Adjust the path accordingly
import authService from "../../services/auth-service.js";

// import Form from './form.js'
import { useEffect, useState, useContext } from "react";
import { AuthContext } from "context"; // Adjust the path as needed
import Loading from "layouts/loading/SimpleBackdrop"

function MessagesTables() {
  const { isAuthenticated, userRole } = useContext(AuthContext);
  // Using the messagesData for the table
  const { columns, rows, update } = messagesData();
  if(update.isLoading){
    return(<Loading />)
  }
  if(!update.isLoading){
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white" display="flex" alignItems="bottom" gap="5px">
                  Messages
                  <MDBox onClick={() => update.rRefereshPage()}>
                    <img src="/icons8-refresh-100.png" width={20} alt="referesh" />
                  </MDBox>
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );}
}

export default MessagesTables;

