import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDBadge from "components/MDBadge";
import AuthService from "../../../services/auth-service";


export default function NewsletterData() {
  const [isLoading, setIsLoading] = useState(false)
  const [subscribers, setSubscribers] = useState([]);
  const [referesh, setReferesh] = useState(true)
  
  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true)
        const data = await AuthService.getNewsLetters(); // Update API call to get newsletter subscriptions
        if (data.error) {
          return console.log(data.error); 
        }
  
        const allSubscribers = data.data;
        setSubscribers(allSubscribers);
        setIsLoading(false)
      } catch (error) {
        console.error("Error fetching newsletter subscribers:", error);
      }
    }
  
    fetchData();
  }, [referesh]);

  const rRefereshPage = () => {
    setReferesh(referesh ? false : true)
  }
  const updateButtonClicked = async (subscriber) => {
    const status = subscriber.status === "Active" ? "Disabled" : "Active"
    const newsData = {data: {attributes: {status}}}
    const newsLetterId = subscriber.subId
    const response = await AuthService.updateNewsLetters(newsLetterId, newsData)
    rRefereshPage()
  }

  // Define Author component
  const Author = ({ name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox ml={2} lineHeight={1} display="flex" flexDirection="column" gap="2px">
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        </MDBox>
      </MDBox>
  );

  // Map fetched data to rows
  const rows = subscribers.map((subscriber) => ({
    // Display subscriber's data
    subscriber: (
      <Author
        name={subscriber.fullname}
      />
    ),
    email: (
      <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
        {subscriber.email}
      </MDTypography>
    ),
    status: (
      <MDBox ml={-1}>
        <MDBadge
          onClick = {()=>updateButtonClicked(subscriber)}
          badgeContent={subscriber.status}
          color={subscriber.status === "Active" ? "success" : "dark"}
          variant="gradient"
          size="sm"
          style={{
            cursor: "pointer", // Adds the pointer cursor
          }}
        />
      </MDBox>
    ),
    date: (
      <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
        {new Date(subscriber.sub_date).toLocaleDateString()}
      </MDTypography>
    ),
  }));

  return {
    columns: [
      { Header: "Subscriber", accessor: "subscriber", width: "45%", align: "left" },
      { Header: "Email", accessor: "email", align: "left" },
      { Header: "Status", accessor: "status", align: "center" },
      { Header: "Date", accessor: "date", align: "center" },
    ],
    rows,
    update: {
      isLoading,
      rRefereshPage
    }
  };
}
