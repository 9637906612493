import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// Publications data
import publicationsTableData from "layouts/publications/data/publicationData.js";
import Form from './form.js';
import { useEffect, useState } from "react";
import Loading from "layouts/loading/SimpleBackdrop"
function Tables() {
  const { columns: pubColumns, rows: pubRows, pubUpdate } = publicationsTableData();
  const [view, setView] = useState('view');
  const [entityType, setEntityType] = useState('');
  const [selected, setSelected] = useState({});

  // Create new publication
  const publicationCreateButtonClicked = async () => {
    setView('create');
    setEntityType('publication');
  };

  // Update view when publication is selected for update
  useEffect(() => {
    if (pubUpdate.isCurrentStateUpdate) {
      setView('update');
      setSelected(pubUpdate.selectedPublicationForUpdate);
      setEntityType('publication');
    }
  }, [pubUpdate]);

  const closeForm = () => {
    pubUpdate.closeTheUpdateStateForm();
    setView('view');
    pubUpdate.refereshPage()
  };

  if (view === 'view') {
    if(pubUpdate.isLoading){
      return(<Loading />)
    }
    if(!pubUpdate.isLoading){
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white" display="flex" alignItems="bottom" gap="5px">
                    Publications
                    <MDBox onClick={publicationCreateButtonClicked}>
                      <img
                        src="/icons8-edit-100.png"
                        width={20}
                        alt="create"
                      />
                    </MDBox>
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <DataTable
                    table={{ columns: pubColumns, rows: pubRows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    );}
  }

  if (view === 'create') {
    return (
      <Form
        entity={{}}
        isUpdateMode={false}
        entityType="publication"
        onClose={closeForm}
      />
    );
  }

  if (view === 'update') {
    return (
      <Form
        entity={selected}
        isUpdateMode={true}
        entityType="publication"
        onClose={closeForm}
      />
    );
  }
}

export default Tables;
