import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import messagesData from "layouts/newsLetters/data/newsLetterData.js"; // Adjust the path accordingly
import authService from "../../services/auth-service.js";

// import Form from './form.js'
import { useEffect, useState, useContext } from "react";
import { AuthContext } from "context"; // Adjust the path as needed
import Loading from "layouts/loading/SimpleBackdrop"
import axios from "axios";
function MessagesTables() {
  const { isAuthenticated, userRole } = useContext(AuthContext);

  // Using the messagesData for the table
  const { columns, rows, update } = messagesData();

  const subscribersExportData = async () => {
    try {
      const response = await axios.get('https://server.belestech.com/subscription/export', {
        responseType: 'blob', // Ensure binary data is handled properly
      });
  
      if (response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement('a');
        a.href = url;
        a.download = 'subscription.xlsx'; // Set the desired filename
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url); // Clean up the object URL
      } else {
        console.error('Failed to export data.');
      }
    } catch (error) {
      console.error('Error exporting data:', error);
    }
  };

  if(update.isLoading){
    return(<Loading />)
  }
  if(!update.isLoading){
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white" display="flex" alignItems="bottom" gap="5px">
                  newsLetter subscribers
                  <MDBox width="80%" style = {{display: "flex", justifyContent: "end"}}>
                      <MDBox onClick={subscribersExportData} style = {{cursor: "pointer"}}>
                        <img
                          src="/icons8-export-100.png"
                          width={30}
                          alt="create"
                        />
                      </MDBox>
                    </MDBox>
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );}

}

export default MessagesTables;


