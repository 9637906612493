import React, { useState, useEffect } from "react";
import { Box, Grid, Card, Button, IconButton, Typography, Pagination } from "@mui/material";
import { Delete, Download, Upload } from "@mui/icons-material";
import axios from "axios";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import authService from "services/auth-service";
import Loading from "layouts/loading/SimpleBackdrop"

const FileManagement = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6; // Limit to 6 items per page

  // Fetch files from the server
  const fetchFiles = async () => {
    try {
      setIsLoading(true)
      const response = await authService.getFiles()
      setFiles(response.files);
      setIsLoading(false)
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  };

  useEffect(() => {
    fetchFiles();
  }, []);

  // Upload a file to the server
  const handleFileUpload = async () => {
    if (!selectedFile) return;
    
    const formData = new FormData();
    formData.append("file", selectedFile);
    try {
      setIsLoading(true)
      await authService.uploadFiles(formData)
      fetchFiles();
      setSelectedFile(null);
      setIsLoading(false)
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  // Download a specific file
  const handleFileDownload = async (filename) => {
    try {
      setIsLoading(true)
      const response = await authService.downloadFiles(filename)
      // axios.get(`/download/${filename}`, {
      //   responseType: "blob",
      // });
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      setIsLoading(false)
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  // Delete a specific file
  const handleFileDelete = async (filename) => {
    const isConfirmed = window.confirm(`Are you sure you want to delete the file ${filename}?`);
    // If user cancels, do nothing
    if (!isConfirmed) return;
    try {
      setIsLoading(true)
      await axios.delete(`/files/${filename}`);
      fetchFiles();
      setIsLoading(false)
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  };

  // Calculate files to display based on current page
  const indexOfLastFile = currentPage * itemsPerPage;
  const indexOfFirstFile = indexOfLastFile - itemsPerPage;
  const currentFiles = files.slice(indexOfFirstFile, indexOfLastFile);

  // Handle page change
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  if(isLoading){
    return(<Loading />)
  }
  if(!isLoading){
  return (
    <Box>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography
                    variant="h6"
                    color="white"
                    display="flex"
                    alignItems="bottom"
                    gap="5px"
                  >
                    File Management
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Card>
                        <MDBox p={3}>
                          {/* File Upload */}
                          <Box mb={3}>
                            <input
                              type="file"
                              onChange={(e) => setSelectedFile(e.target.files[0])}
                              style={{ marginRight: "10px" }}
                            />
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={handleFileUpload}
                              startIcon={<Upload />}
                              disabled={!selectedFile}
                            >
                              Upload
                            </Button>
                          </Box>

                          {/* File List */}
                          <Grid container spacing={2}>
                            {currentFiles.map((file, index) => (
                              <Grid item xs={12} key={index}>
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  p={2}
                                  border={1}
                                  borderColor="grey.300"
                                  borderRadius="5px"
                                >
                                  <MDTypography>{file}</MDTypography>
                                  <Box>
                                    <IconButton
                                      color="info"
                                      onClick={() => handleFileDownload(file)}
                                    >
                                      <Download />
                                    </IconButton>
                                    <IconButton
                                      color="secondary"
                                      onClick={() => handleFileDelete(file)}
                                    >
                                      <Delete />
                                    </IconButton>
                                  </Box>
                                </Box>
                              </Grid>
                            ))}
                          </Grid>

                          {/* Pagination */}
                          <Box display="flex" justifyContent="center" mt={3}>
                            <Pagination
                              count={Math.ceil(files.length / itemsPerPage)}
                              page={currentPage}
                              onChange={handlePageChange}
                              color="info"
                            />
                          </Box>
                          
                        </MDBox>
                      </Card>
                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </Box>
  );
};}

export default FileManagement;

