import { useState, useEffect } from "react";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";
import MenuItem from "@mui/material/MenuItem"; // MenuItem for dropdown options
import { Select, InputLabel, FormControl, Checkbox, FormControlLabel } from "@mui/material";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Overview page components
import Header from "layouts/user-profile/Header";
import AuthService from "../../services/auth-service";
import Loading from "layouts/loading/SimpleBackdrop"

const UserProfile = ({ usersList, user: propUser, isUpdateMode, onClose }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [notification, setNotification] = useState(false);
  const [user, setUser] = useState({
    userName: "",
    name: "",
    email: "",
    role: "",
    contactInfo: "",
    password: "",
    img: "",
  });
  const [images, setImages] = useState(null)

  const [errors, setErrors] = useState({
    nameError: false,
    emailError: false,
    passwordError: false,
  });

  const [selectedUser, setSelectedUser] = useState(""); // For the user dropdown
  const [updatePassword, setUpdatePassword] = useState(false); // Toggle for updating password
  const [updateImage, setUpdateImage] = useState(false); // Toggle for updating image

  // Handle notification for profile update
  useEffect(() => {
    if (notification) {
      const timer = setTimeout(() => {
        setNotification(false);
      }, 5000);
      return () => clearTimeout(timer); // Clean up the timer
    }
  }, [notification]);

  // Update the form's user state when props.user changes
  useEffect(() => {
    if (propUser) {
      setUser((prevUser) => ({
        ...prevUser,
        ...propUser,
      }));
    }
  }, [propUser]);

  const changeHandler = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const togglePasswordUpdate = () => {
    setUpdatePassword(!updatePassword);
  };

  const toggleImageUpdate = () => {
    setUpdateImage(!updateImage);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    // Validation
    const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (user.name.trim().length === 0) {
      setErrors({ ...errors, nameError: true });
      return;
    }
    if (user.email.trim().length === 0 || !user.email.trim().match(mailFormat)) {
      setErrors({ ...errors, emailError: true });
      return;
    }
    if (user.password.trim().length < 8 && !propUser) {
      setErrors({ ...errors, passwordError: true });
      return;
    }

    // Prepare data for API call
    let userData = {
      userName: user.userName,
      name: user.name,
      email: user.email,
      role: user.role,
      contactInfo: user.contactInfo,
      img: user.img,
    };
    
    // Add password only when creating or updating password
    if (user.password.length > 0) {
      userData.password = user.password;
    }
    const fullUserData = {
      data: {
        attributes: userData
      }
    }
    const formData = new FormData();
    formData.append("images", images);
    formData.append("data", JSON.stringify(fullUserData)); 
    // Call API for create/update
    const response = isUpdateMode
      ? await AuthService.updateUsers({formData, userName: userData.userName})  // Update API
      : await AuthService.createUsers(formData); // Create API

    // Reset errors
    setErrors({
      nameError: false,
      emailError: false,
      passwordError: false,
    });
    setIsLoading(false)
    setNotification(true);
    if (onClose) onClose(); // Close the form after submission
  };
  if(isLoading){
    return(<Loading />)
  }
  if(!isLoading){
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header name={user.name} role={user.role}>
        {notification && (
          <MDAlert color="info" mt="20px">
            <MDTypography variant="body2" color="green">
              {isUpdateMode ? "Profile has been updated" : "User created successfully"}
            </MDTypography>
          </MDAlert>
        )}
        <MDBox
          component="form"
          role="form"
          onSubmit={submitHandler}
          display="flex"
          flexDirection="column"
        >
          {/* User Name */}
          <MDBox mb={2}>
            <MDTypography variant="body2" color="text" ml={1}>
              {isUpdateMode ? "Edit Username" : "Create Username"}
            </MDTypography>
            <MDInput
              type="text"
              name="userName"
              value={user.userName}
              onChange={changeHandler}
              disabled={isUpdateMode}
              fullWidth
            />
          </MDBox>

          {/* Name */}
          <MDBox mb={2}>
            <MDTypography variant="body2" color="text" ml={1}>
              Full Name
            </MDTypography>
            <MDInput
              type="text"
              name="name"
              value={user.name}
              onChange={changeHandler}
              error={errors.nameError}
              fullWidth
            />
            {errors.nameError && (
              <MDTypography variant="caption" color="error">
                Name is required
              </MDTypography>
            )}
          </MDBox>

          {/* Email */}
          <MDBox mb={2}>
            <MDTypography variant="body2" color="text" ml={1}>
              Email Address
            </MDTypography>
            <MDInput
              type="email"
              name="email"
              value={user.email}
              onChange={changeHandler}
              error={errors.emailError}
              fullWidth
            />
            {errors.emailError && (
              <MDTypography variant="caption" color="error">
                A valid email is required
              </MDTypography>
            )}
          </MDBox>

          {/* Password (Always show when creating a new user) */}
          {!isUpdateMode && (
            <MDBox mb={2}>
              <MDTypography variant="body2" color="text" ml={1}>
                Password
              </MDTypography>
              <MDInput
                type="password"
                name="password"
                value={user.password}
                onChange={changeHandler}
                error={errors.passwordError}
                fullWidth
              />
              {errors.passwordError && (
                <MDTypography variant="caption" color="error">
                  Password must be at least 8 characters
                </MDTypography>
              )}
            </MDBox>
          )}

          {/* Role */}
          <MDBox mb={2} display="flex" alignItems="center" gap="4px">
            <MDTypography variant="body2" color="text" ml={1}>
              Role
            </MDTypography>
            <FormControl padding="4px 2px">
              <InputLabel id="role-select-label">Role</InputLabel>
              <Select
                labelId="role-select-label"
                name="role"
                value={user.role || ""}
                onChange={changeHandler}
              >
                <MenuItem value="admin">Admin</MenuItem>
                <MenuItem value="researchHead">Research Head</MenuItem>
                <MenuItem value="projectHead">Project Head</MenuItem>
                
              </Select>
            </FormControl>
          </MDBox>

          {/* Contact Info */}
          <MDBox mb={2}>
            <MDTypography variant="body2" color="text" ml={1}>
              Contact Information
            </MDTypography>
            <MDInput
              type="text"
              name="contactInfo"
              value={user.contactInfo}
              onChange={changeHandler}
              fullWidth
            />
          </MDBox>

          {/* Password Update Toggle (Only show when updating a user) */}
          {isUpdateMode && (
            <MDBox mb={2}>
              <MDTypography variant="body2" color="text" ml={1}>
                Update Password
              </MDTypography>
              <MDBox >
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="isRelatedToResearch"
                          checked={updatePassword}
                          onChange={togglePasswordUpdate}
                        />
                      }
                      label=" Do you want to update the img?"
                    />
                  </MDBox>
            </MDBox>
          )}

          {/* Conditionally Render Password Inputs for Password Update */}
          {updatePassword && (
            <>
              <MDBox mb={2}>
                <MDTypography variant="body2" color="text" ml={1}>
                  New Password
                </MDTypography>
                <MDInput
                  type="password"
                  name="newPassword"
                  value={user.newPassword}
                  onChange={changeHandler}
                  fullWidth
                />
              </MDBox>

              <MDBox mb={2}>
                <MDTypography variant="body2" color="text" ml={1}>
                  Confirm Password
                </MDTypography>
                <MDInput
                  type="password"
                  name="confirmPassword"
                  value={user.confirmPassword}
                  onChange={changeHandler}
                  fullWidth
                />
              </MDBox>
            </>
          )}

          {/* Image Upload */}
          {isUpdateMode ? (
            <>
              <MDBox mb={2}>
                <MDTypography variant="body2" color="text" ml={1}>
                  Update Profile Image?
                </MDTypography>
                  <MDBox >
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="isRelatedToResearch"
                          checked={updateImage}
                          onChange={toggleImageUpdate}
                        />
                      }
                      label=" Do you want to update the img?"
                    />
                  </MDBox>
              </MDBox>
              {updateImage && (
                <MDBox mb={2}>
                  <MDTypography variant="body2" color="text" ml={1}>
                    New Image
                  </MDTypography>
                  <MDInput
                    type="file"
                    name="img"
                    onChange={(e) => changeHandler(e)}
                    fullWidth
                  />
                </MDBox>
              )}
            </>
          ) : (
            <MDBox mb={2}>
              <MDTypography variant="body2" color="text" ml={1}>
                Upload Profile Image
              </MDTypography>
              <MDInput
                type="file"
                name="img"
                onChange={(e) => setImages(e.target.files[0])}
                fullWidth
              />
            </MDBox>
          )}

          {/* Submit Button */}
          <MDBox display="flex" justifyContent="right" mt={3}>
          <MDButton variant="outlined" color="secondary" onClick={onClose} sx={{ marginRight: 2 }}>
              Close
            </MDButton>
            <MDButton
              variant="gradient"
              color="info"
              type="submit"
            >
              {isUpdateMode ? "Update Profile" : "Create User"}
            </MDButton>
          </MDBox>
        </MDBox>
      </Header>
      <Footer />
    </DashboardLayout>
  );
};}

export default UserProfile;


