import { io } from "socket.io-client";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
// import dotenv from 'dotenv'
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import authService from "services/auth-service";
// dotenv.config()
// Set up the socket

const socket = io("https://server.belestech.com"); // Replace with your server URL

// const socket = io("http://localhost:8080"); // Replace with your server URL
import { useEffect, useState, useContext } from "react";
import { AuthContext } from "context"; // Adjust the path as neede
import Loading from "layouts/loading/SimpleBackdrop"

function Notifications() {
  const [isLoading, setIsLoading] = useState(false)
  const [notifications, setNotifications] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState({});
  const { isAuthenticated, userRole } = useContext(AuthContext);
  useEffect(() => {
    // Fetch notifications on component mount
    fetchNotifications();

    // Subscribe to new notifications via socket
    socket.on("notification", (data) => {
      setNotifications((prevNotifications) => [...prevNotifications, data]);
      // openSnackbarHandler(data);
    });

    return () => {
      socket.off("notification");
    };
  }, []);

  // Fetch notifications from the server
  const fetchNotifications = async () => {
    try {
      setIsLoading(true)
      const response = await authService.getNotifications() 
      setNotifications(response.data);
      setIsLoading(false)
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  // Add a new notification
  const addNotification = async (msg) => {
    try {
      const response = await authService.createNotifications({data: {attributes: msg}})
      setNotifications((prev) => [...prev, response]);
    } catch (error) {
      console.error("Error adding notification:", error);
    }
  };

  // Delete a notification
  const deleteNotification = async (id) => {
    const isConfirmed = window.confirm(`Are you sure you want to delete notification ${id}?`);
    // If user cancels, do nothing
    if (!isConfirmed) return;
    try {
      setIsLoading(true)
      await authService.deleteNotifications(id)
      setNotifications((prev) => prev.filter((notif) => notif.notificationsId !== id));
      setIsLoading(false)
    } catch (error) {
      console.error("Error deleting notification:", error);
    }
  };

  // Mark a notification as viewed
  const markAsViewed = async (id) => {
    try {
      setIsLoading(true)
      await authService.updateNotifications({fullNotificationsData: {data: { attributes: { viewed: 1 }} } , notificationId: id})
      setNotifications((prev) =>
        prev.map((notif) => (notif.notificationsId === id ? { ...notif, viewed: 1 } : notif))
      );
      setIsLoading(false)
    } catch (error) {
      console.error("Error updating notification:", error);
    }
  };

  // Open a snackbar notification
  const openSnackbarHandler = (notification) => {
    setOpenSnackbar({ open: true, content: notification.msg });
  };

  const closeSnackbarHandler = () => {
    setOpenSnackbar({ open: false, content: "" });
  };
  // Utility function to format date into "time ago" format
  const formatTimeAgo = (dateString) => {
  const now = new Date();
  const date = new Date(dateString);
  const diffInSeconds = Math.floor((now - date) / 1000);

  const rtf = new Intl.RelativeTimeFormat("en", { numeric: "auto" });

  if (diffInSeconds < 60) return rtf.format(-diffInSeconds, "second");
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  if (diffInMinutes < 60) return rtf.format(-diffInMinutes, "minute");
  const diffInHours = Math.floor(diffInMinutes / 60);
  if (diffInHours < 24) return rtf.format(-diffInHours, "hour");
  const diffInDays = Math.floor(diffInHours / 24);
  if (diffInDays < 7) return rtf.format(-diffInDays, "day");
  
  return "a long time ago"; // Fallback for times longer than a week
};
  if(isLoading){
    return(<Loading />)
  }
  if(!isLoading){
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={8}>
          <Card>
  <MDBox p={2}>
    <MDTypography variant="h5">Notifications</MDTypography>
    {notifications.map((notification) => (
      <MDBox
        key={notification.notificationsId}
        mb={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <MDTypography
          variant="body2"
          sx={{ flexGrow: 1, overflowWrap: "break-word" }}
        >
          {notification.msg}
        </MDTypography>
        
        <MDBox display="flex" gap={1}>
          <MDButton
            variant="text"
            color="info"
            onClick={() => markAsViewed(notification.notificationsId)}
            disabled={notification.viewed}
          >
            Mark as viewed
          </MDButton>
          {userRole === "admin" && (<MDButton
            variant="text"
            color="error"
            onClick={() => deleteNotification(notification.notificationsId)}
          >
            Delete
          </MDButton>)}
        </MDBox>
        <MDTypography
          variant="body2"
          // color = "blue"
          // sx={{ flexGrow: 1, overflowWrap: "break-word", mr: 2 }}
        >
           {formatTimeAgo(notification.DateTime)}
        </MDTypography>
      </MDBox>
    ))}
  </MDBox>
</Card>

          </Grid>
        </Grid>
      </MDBox>
      <MDSnackbar
        color="info"
        icon="notifications"
        title="New Notification"
        content={openSnackbar.content}
        open={openSnackbar.open}
        onClose={closeSnackbarHandler}
        close={closeSnackbarHandler}
      />
      <Footer />
    </DashboardLayout>
  );}
}

export default Notifications;
